import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { newUpdateKey, setReduxAssignment } from '../../actions';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import { API_URL } from '../../utils/constants';
import { formDataToObjectParsed, getPhaseStatus } from '../../utils/functions';
import {
  editAssignment,
  forceResendLTIResult,
  getLineItemForAssignment,
  recalculateGrades,
  releaseResults,
} from '../../utils/requests';
import Button from '../core/button/Button/Button';
import AlertBar from '../core/display/AlertBar';
import Icon from '../core/display/Icon';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import { TeacherResultsTabProps } from './TeacherResultsPage';
import { useSelector } from 'react-redux';
import { LineItem } from '../../types/types';
import { selectAssignment, selectCourse } from '../../store/selectors';
import { Assignment } from '../../types/types';

interface Props extends TeacherResultsTabProps {
  resultsReady?: boolean;
}

function TeacherOverviewMenu({ resultsReady = false, updateData, accessPermission }: Props): JSX.Element {
  const { courseId, assignmentId } = useParams() as { courseId: string; assignmentId: string };
  const rootPathWithIds = `/course/${courseId}/assignment/${assignmentId}/results`;

  const dispatch = useDispatch();
  const { modalDispatch } = useModalContext();
  const course = useSelector(selectCourse);
  const assignment = useSelector(selectAssignment) as Assignment;

  const [manualGradeRelease, setManualGradeRelease] = useState(assignment.manualGradeRelease);
  const [lineItem, setLineItem] = useState<LineItem | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getLineItemForAssignment(courseId, assignmentId, setLineItem);
  }, [assignmentId, courseId]);

  const handleRecalculateSelect = useCallback(() => {
    modalDispatch(
      openModal({
        heading: 'Recalculate Grades',
        label: 'Are you sure you want to recalculate assignment grades? The current grades will be overwritten.',
        buttonText: 'Continue',
        onConfirm: () => {
          setLoading(true);
          recalculateGrades(assignmentId, () => {
            setLoading(false);
            updateData();
            modalDispatch(
              openModal({
                heading: 'Grades Recalculated',
                label: 'Assignment grades have been recalculated.',
                buttonText: 'Continue',
                cancelHide: true,
              }),
            );
            dispatch(newUpdateKey());
          });
        },
      }),
    );
  }, [modalDispatch, assignmentId, updateData, dispatch]);

  const handleReleaseSelect = useCallback(() => {
    modalDispatch(
      openModal({
        heading: 'Release Results',
        label: 'Are you sure you want to release the results to your students?',
        onConfirm: () => {
          setLoading(true);
          releaseResults(assignmentId, () => {
            setLoading(false);
            setManualGradeRelease(false);
            modalDispatch(
              openModal({
                heading: 'Results Released',
                label: 'Assignment results have been released.',
                buttonText: 'Continue',
                cancelHide: true,
              }),
            );
            dispatch(newUpdateKey());
          });
        },
      }),
    );
  }, [assignmentId, dispatch, modalDispatch]);

  const handleResendLTIResult = useCallback(() => {
    modalDispatch(
      openModal({
        heading: 'Resend LTI Results',
        label: 'Are you sure you want to force resend the results of this assignment to your gradebook?',
        onConfirm: () => {
          setLoading(true);
          forceResendLTIResult(assignmentId, () => {
            setLoading(false);
            modalDispatch(
              openModal({
                heading: 'Results Resend',
                label: 'Assignment results have been sent to your gradebook.',
                buttonText: 'Continue',
                cancelHide: true,
              }),
            );
          });
        },
      }),
    );
  }, [assignmentId, modalDispatch]);

  const handleAdvancedVisibilitySettings = useCallback(() => {
    const phaseStatus = getPhaseStatus(assignment);
    modalDispatch(
      openModal({
        heading: 'Advanced Visibility Settings',
        buttonText: 'Save',
        padding: '2rem',
        form: true,
        onSubmit: (formData) => {
          const settings = formDataToObjectParsed(formData) as {
            hideCommentResults: boolean;
            hideGradeResults: boolean;
            hideRatingResult: boolean;
            hideGroupFormationResults?: boolean;
          };
          editAssignment(assignmentId, { ...assignment, ...settings }, (data) => dispatch(setReduxAssignment(data)));
        },
        children: (
          <div id="advanced-visibility-settings-menu">
            {phaseStatus.submission || phaseStatus.evaluate ? (
              <>
                <div className="visibility-setting">
                  <label htmlFor="hideRatingResults">Rating Results</label>
                  <select
                    id="hideRatingResults"
                    name="hideRatingResults"
                    defaultValue={assignment.hideRatingResults + ''}
                  >
                    <option value="false">Show</option>
                    <option value="true">Hide</option>
                  </select>
                </div>
                <div className="visibility-setting">
                  <label htmlFor="hideCommentResults">Comment Results</label>
                  <select
                    id="hideCommentResults"
                    name="hideCommentResults"
                    defaultValue={assignment.hideCommentResults + ''}
                  >
                    <option value="false">Show</option>
                    <option value="true">Hide</option>
                  </select>
                </div>
              </>
            ) : null}
            {phaseStatus.groupFormation ? (
              <div className="visibility-setting">
                <label htmlFor="hideGroupFormationResults">Group Formation Survey Results</label>
                <select
                  id="hideGroupFormationResults"
                  name="hideGroupFormationResults"
                  defaultValue={assignment.hideGroupFormationResults + ''}
                >
                  <option value="false">Show</option>
                  <option value="true">Hide</option>
                </select>
              </div>
            ) : null}
            <div className="visibility-setting">
              <label htmlFor="hideGradeResults">Grade Results</label>
              <select id="hideGradeResults" name="hideGradeResults" defaultValue={assignment.hideGradeResults + ''}>
                <option value="false">Show</option>
                <option value="true">Hide</option>
              </select>
            </div>
          </div>
        ),
      }),
    );
  }, [assignment, assignmentId, dispatch, modalDispatch]);

  const alertBars = [];
  if (assignment.benchmarkGradingRequired)
    alertBars.push(<AlertBar>You must Benchmark Grades before results can be released to students</AlertBar>);
  if (manualGradeRelease)
    alertBars.push(
      <AlertBar>Grades must be released manually before they are visible to students (See below)</AlertBar>,
    );

  return (
    <>
      <div id="actions-card" className="panel-sm">
        <div className="control-title">
          <h2>Controls</h2>
          {assignment.connectedToLineItem && assignment.lineItemId != null ? (
            <div className="icon-wrapper">
              <Icon code="check" />
              <div className="tooltip">Connect to LMS LineItem</div>
            </div>
          ) : null}
        </div>

        {alertBars.length > 0 ? alertBars[0] : null}

        <p className="visibility-status">
          <b>Results Visibility:</b>
          <span className="with-icon">
            {(assignment.status === 'COMPLETE' || course?.asyncEnabled) && !assignment.manualGradeRelease ? (
              <>
                <Icon code="visibility" ariaHidden /> Released to Students
              </>
            ) : (
              <>
                <Icon code="visibility_off" ariaHidden /> Hidden from Students
              </>
            )}
          </span>
        </p>

        {manualGradeRelease ? (
          <Button className="with-icon" variant="low" onClick={handleReleaseSelect}>
            <Icon code="send" ariaHidden />
            Release Results
          </Button>
        ) : null}

        <Button variant="alt low" onClick={handleAdvancedVisibilitySettings}>
          Advanced Visibility Settings
        </Button>

        {resultsReady ? (
          <>
            {assignment.benchmarkGradingAvailable ? (
              <Button variant="low" href={`${rootPathWithIds}/benchmark`} route>
                Benchmark Grades
              </Button>
            ) : null}

            <Button variant="low" onClick={handleRecalculateSelect}>
              Recalculate Grades
            </Button>

            {lineItem ? (
              <Button variant="low" onClick={handleResendLTIResult}>
                Force Resend LTI Results
              </Button>
            ) : null}
            {accessPermission.downloadResultPermission ? (
              <Button variant="low" href={`${API_URL}/assignment/${assignmentId}/results/export`}>
                Export Results
              </Button>
            ) : null}
            <Button variant="low" href={`${API_URL}/assignment/${assignmentId}/submissions/export`}>
              Export Submissions
            </Button>
            {assignment.groupsEnabled ? (
              <Button variant="low" href={`${API_URL}/assignment/${assignmentId}/groups/export`}>
                Export Group Roster
              </Button>
            ) : null}
            {assignment.groupFormationEnabled ? (
              <Button variant="low" href={`${API_URL}/assignment/${assignmentId}/group_formation/export`}>
                Export Group Formation Data
              </Button>
            ) : null}
          </>
        ) : null}
      </div>

      {loading ? <LoadingSpinner /> : null}
    </>
  );
}

export default TeacherOverviewMenu;
