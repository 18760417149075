import React, { useMemo } from 'react';
import { GroupFormationResult } from '../../types/types';
import BasicScheduler, { initializeSchedule } from '../core/input/Scheduler/BasicScheduler';

interface Props {
  results: GroupFormationResult[];
}

function GroupFormationResults({ results }: Props): JSX.Element {
  return (
    <>
      {results.map((result, index) => {
        const sharedProps = { result, index };
        switch (result.prompt.type) {
          case 'SCHEDULE':
            return <SchedulerResults {...sharedProps} />;
          case 'RANKED_CHOICE':
            return <RankedChoiceResults {...sharedProps} />;
          case 'CHOOSE_ONE':
            return <MultipleChoiceResults {...sharedProps} />;
        }
      })}
    </>
  );
}

interface SectionProps {
  index: number;
  result: GroupFormationResult;
}

function SchedulerResults({ index, result }: SectionProps): JSX.Element {
  const initSchedule = useMemo(() => {
    const initSchedule = initializeSchedule();
    result.data.forEach((dataMap, i) => {
      const percentAvailable = dataMap.hasOwnProperty(1) ? dataMap[1] : 0;
      initSchedule[Math.floor(i / 3)].availability[i % 3].percentAvailable = percentAvailable;
      initSchedule[Math.floor(i / 3)].availability[i % 3].isAvailable = percentAvailable > 0;
    });
    return initSchedule;
  }, [result]);

  return (
    <div className="scheduler-results results-section">
      <h2>[{index + 1}] Scheduler</h2>
      <p>This shows, given the day and time, how much of your group is available during the week.</p>
      <BasicScheduler initSchedule={initSchedule} readOnly />
    </div>
  );
}

type RankedChoiceResult = {
  option: string;
  data: { [index: number]: number };
  score: number;
  rank: number;
};

const calculateRankScore = (data: { [index: number]: number }, numRanks: number): number => {
  const scores = Object.keys(data).map((rank) => (numRanks - Number(rank)) * data[Number(rank)]);
  return scores.reduce((a, b) => a + b, 0);
};

const compareRankedChoiceResult = (a: RankedChoiceResult, b: RankedChoiceResult): number => {
  if (a.score < b.score) return 1;
  else if (a.score > b.score) return -1;
  const lastRankForA = Math.max(...Object.keys(a.data).map((key) => Number(key)));
  const lastRankForB = Math.max(...Object.keys(b.data).map((key) => Number(key)));
  for (let i = 0; i < Math.max(lastRankForA, lastRankForB); i++) {
    const percentOfRankForA = a.data.hasOwnProperty(i) ? a.data[i] : 0;
    const percentOfRankForB = b.data.hasOwnProperty(i) ? b.data[i] : 0;
    if (percentOfRankForA !== percentOfRankForB) return percentOfRankForB - percentOfRankForA;
  }
  return 0;
};

function RankedChoiceResults({ index, result }: SectionProps): JSX.Element {
  const sortedChoices: RankedChoiceResult[] = useMemo(
    () =>
      result.prompt.options
        .map((option, i) => ({
          option,
          data: { ...result.data.map((datum) => datum[i] ?? 0) },
          score: calculateRankScore(result.data[i], result.prompt.options.length),
          rank: -1,
        }))
        .sort(compareRankedChoiceResult),
    [result],
  );

  const rankedChoices = useMemo(
    () =>
      sortedChoices.map((choice, i) => {
        if (i === 0) choice.rank = 1;
        else if (compareRankedChoiceResult(sortedChoices[i - 1], choice) === 0) choice.rank = sortedChoices[i - 1].rank;
        else choice.rank = i + 1;
        return choice;
      }),
    [sortedChoices],
  );

  return (
    <div className="ranked-choice-results results-section">
      <h2>[{index + 1}] Ranked Choice</h2>
      <p>Here&apos;s how your group collectively ranked the following options:</p>
      <table className="placements">
        <thead>
          <tr>
            <th>Rank</th>
            <th>Choice</th>
            <th>Rank Distribution</th>
          </tr>
        </thead>
        <tbody>
          {rankedChoices.map((rankedChoice) => (
            <tr key={rankedChoice.option} className={['gold', 'silver', 'bronze'][rankedChoice.rank - 1]}>
              <td className="rank">#{rankedChoice.rank}</td>
              <td className="option">{rankedChoice.option}</td>
              <td>
                <ol style={{ columnCount: Math.ceil(result.prompt.options.length / 3) }}>
                  {[...Array(result.prompt.options.length).keys()].map((rankedPosition) => {
                    const positionPercent = rankedChoice.data.hasOwnProperty(rankedPosition)
                      ? rankedChoice.data[rankedPosition]
                      : 0;
                    return (
                      <li key={rankedPosition} style={{ opacity: positionPercent === 0 ? 0.3 : 1 }}>
                        <b>#{rankedPosition + 1}</b> {positionPercent}%
                      </li>
                    );
                  })}
                </ol>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

type MultipleChoiceResult = {
  option: string;
  percent: number;
};

function MultipleChoiceResults({ index, result }: SectionProps): JSX.Element {
  const sortedMultipleChoices: MultipleChoiceResult[] = useMemo(
    () =>
      result.prompt.options
        .map((option, i) => ({ option, percent: result.data[0][i] ?? 0 }))
        .sort((a, b) => b.percent - a.percent),
    [result],
  );

  return (
    <div className="multiple-choice-results results-section">
      <h2>[{index + 1}] Multiple Choice</h2>
      <p>Question: &quot;{result.prompt.description}&quot;</p>
      <table className="placements">
        <thead>
          <tr>
            <th>Answer</th>
            <th>Percent</th>
          </tr>
        </thead>
        <tbody>
          {sortedMultipleChoices.map((choice, i) => (
            <tr
              key={choice.option}
              className={i === 0 || choice.percent >= sortedMultipleChoices[0].percent ? 'winner' : undefined}
              style={{ backgroundColor: `rgba(99, 169, 215, ${choice.percent / 100})` }}
            >
              <td>{choice.option}</td>
              <td>{choice.percent}%</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default GroupFormationResults;
